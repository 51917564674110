import Logo from "../../assets/img/logo.svg";
import {useNavigate} from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();

  return (
    <footer className="footer">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-6">
            <div className="footer__Content">
              <a href="#" onClick={() => navigate('/') }>
                <img src={Logo} alt={''} />
              </a>
            </div>
          </div>

          {/*<div className="col-lg-6 col-md-6">
            <div className="footer__Content">
              <ul>
                <li><a href="#" onClick={() => navigate('/privacy-policy') }>Privacy Policy</a></li>
                <li><a href="#" onClick={() => navigate('/terms') }>Terms and Conditions</a></li>
              </ul>
            </div>
          </div>*/}
        </div>
      </div>
    </footer>
  )
}
export default Footer;
