import { useNavigate } from "react-router-dom";
import errorImg from "../../assets/img/error-img.svg";
import Header from "../../widgets/Header";
import Footer from "../../widgets/Footer";
const PageNotFound = () => {
  const navigate = useNavigate();
  return (<>
  <Header />
    <div className="errorSection">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="text-center">
              <img className="mb-5 errorImg" src={errorImg}  alt={"Error"}/>
              <a href="#" onClick={() => navigate(-1)}>
                <i className="fa-solid fa-angles-left"></i> Go Back
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
    </>
  );
};
export default PageNotFound
