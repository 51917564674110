import React, {useRef, useState} from "react";
import Header from "../../widgets/Header";
import LeftBubble from "../../assets/img/leftBubble.svg"
import RightBubble from "../../assets/img/rightBubble.svg"
import PlayBtn from "../../assets/img/playBtn.svg"
import PauseIcon from "../../assets/img/pauseIcon.svg"
import TakeAwayImg from "../../assets/img/takeAwayImg.svg"
import PromotionColonel from "../../assets/video/PromotionColonel.mp4"
import PdfFile from "../../assets/pdf/14Sep_Snel_Joseph (1).pdf"
import Footer from "../../widgets/Footer";
import {ErrorMessage, Field, Form, Formik} from 'formik';
import * as Yup from 'yup';
import ErrorMessageWrapper from "../../components/ErrorMessageWrapper";
import _ from "lodash";

const Welcome = () => {
  const videoRef = useRef(null);
  const formikRef = useRef(null); // create a ref to the Formik component


  const [isPlayingVideo, setIsPlayingVideo] = useState(false);
  const [showPlay, setShowPlay] = useState(true);
  const [finalResult, setFinalResult] = useState(0);
  const initialValues = {
    senior_service_college: '',
    master_degree: '',
    longest_deploy: '',
    total_acom: '',
    battalion_command: '',
    zone_of_consideration: '',
    age: '',
    ltc_ratings: '',
  };
  const validationSchema = Yup.object({
    senior_service_college: Yup.string().required('This field is required.'),
    master_degree: Yup.string().required('This field is required.'),
    longest_deploy: Yup.number().required('This field is required.'),
    total_acom: Yup.number().required('This field is required.'),
    battalion_command: Yup.string().required('This field is required.'),
    zone_of_consideration: Yup.string().required('This field is required.'),
    age: Yup.number().min(1).max(100).required('This field is required.'),
    ltc_ratings: Yup.string().required('This field is required.'),
  });
  const handleFormSubmit = (values, actions) => {
    calculateData(values);
    formikRef.current = actions;
    document.getElementById('resultModalSpanId').click();
  }

  const handleMouseEnter = (() => {
    if (isPlayingVideo) {
      setShowPlay(true);
    }
  });

  const handleMouseLeave = (() => {
    if (isPlayingVideo) {
      setShowPlay(false);
    }
  });

  const toggleVideoPlay = (() => {
    if (isPlayingVideo) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setIsPlayingVideo(!isPlayingVideo);
  });

  const calculateData = (formData) => {
    if (formData) {
      let total = 0;
      if (formData.senior_service_college && formData.senior_service_college === 'no') {
        total += -0.8512225
      }
      if (formData.master_degree && formData.master_degree === 'no') {
        total += -0.51642608
      }
      if (formData.longest_deploy) {
        total += _.toNumber(_.toNumber(formData.longest_deploy) * 0.1441119)
      }
      if (formData.battalion_command && formData.battalion_command === 'no') {
        total += -0.51063159
      }
      if (formData.total_acom) {
        total += ((7.8327551/100) * _.toNumber(formData.total_acom))
      }
      if (formData.zone_of_consideration) {
        if (formData.zone_of_consideration === 'primary_zone') {
          total += 2.0546601
        }
        if (formData.zone_of_consideration === 'below_zone') {
          total += -3.22662287
        }
        if (formData.zone_of_consideration === '') {}
      }
      if (formData.age) {
        total += (_.toNumber(formData.age) * -0.17352285)
      }
      if (formData.ltc_ratings) {
        total += (_.toNumber(formData.ltc_ratings) * 0.3107868)
      }
      let finalResult = 1/( 1 + Math.exp(-1 * (total - 0.46468758)))
      finalResult = _.round((finalResult * 100), 2)
      setFinalResult(finalResult);
    }
  }

  return (
    <>
      <Header />
      <section className="calcSection" id="calc">
        <span className="leftBubble">
            <img src={LeftBubble} alt={''} />
        </span>
        <span className="rightBubble">
            <img src={RightBubble} alt={''} />
        </span>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-6">
              <div className="calcSection__Content">
                <h1>Estimate your probability of promotion to <span>Colonel.</span></h1>
                <p>This calculator is based on research by LTC(R)
                  <a target="_blank" href={PdfFile}>Joseph Snel in 2014</a>.
                  It models the effect of certain factors on AGR COL promotion boards held
                  from <span>2009-2011.</span> Factors influencing future promotion boards may vary based on the needs
                  of the service. <span className="red">*</span><b>Results are not guaranteed</b><span
                    className="red">*</span></p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="calcSection__Form">
                <h3>COL Promotion Calculator</h3>
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={async (values, actions) => {
                    handleFormSubmit(values, actions);
                  }}
                >
                  {() => (
                    <Form id="form" autoComplete="off">
                      <div className="row">
                        <div className="col-lg-6 col-md-6">
                          <div className="form-group">
                            <label htmlFor="senior_service_college">Senior Service College <span>*</span></label>
                            <div className="d-flex" role="group">
                              <div className="form-check me-2 mb-0">
                                <Field className="form-check-input" type="radio" name="senior_service_college" id="flexRadioDefault1" value="yes"/>
                                <label className="form-check-label mb-0" htmlFor="flexRadioDefault1">
                                  Yes
                                </label>
                              </div>

                              <div className="form-check mb-0">
                                <Field className="form-check-input" type="radio" name="senior_service_college" id="flexRadioDefault1" value="no"/>
                                <label className="form-check-label mb-0" htmlFor="flexRadioDefault2">
                                  No
                                </label>
                              </div>
                            </div>
                            <ErrorMessage name={'senior_service_college'} >
                              {ErrorMessageWrapper}
                            </ErrorMessage>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <div className="form-group">
                            <label htmlFor="master_degree">Masters Degree <span>*</span></label>
                            <div className="d-flex">
                              <div className="form-check me-2 mb-0">
                                <Field className="form-check-input" name="master_degree" type="radio" id="flexRadioDefault3" value="yes" />
                                <label className="form-check-label mb-0" htmlFor="flexRadioDefault3">
                                  Yes
                                </label>
                              </div>

                              <div className="form-check mb-0">
                                <Field className="form-check-input" name="master_degree" type="radio" id="flexRadioDefault4" value="no" />
                                <label className="form-check-label mb-0" htmlFor="flexRadioDefault4">
                                  No
                                </label>
                              </div>
                            </div>
                            <ErrorMessage name={'master_degree'} >
                              {ErrorMessageWrapper}
                            </ErrorMessage>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-6 col-md-6">
                          <div className="form-group">
                            <label>Longest Deploy (Months) <span>*</span></label>
                            <Field type="number" className="form-control" id="longest_deploy" name="longest_deploy" placeholder="Longest Deploy in Months"/>
                            <ErrorMessage name={'longest_deploy'} >
                              {ErrorMessageWrapper}
                            </ErrorMessage>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <div className="form-group">
                            <label>% Total ACOM <span>*</span> <span className="tooltipInnter">Above Center of Mass</span></label>
                            <Field type="number" className="form-control" placeholder="Total Acom" id="total_acom" name="total_acom"/>
                            <ErrorMessage name={'total_acom'} >
                              {ErrorMessageWrapper}
                            </ErrorMessage>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-6 col-md-6">
                          <div className="form-group">
                            <label htmlFor="battalion_command">Battalion Command <span>*</span></label>
                            <div className="d-flex">
                              <div className="form-check me-2 mb-0">
                                <Field className="form-check-input" name="battalion_command" value="yes" type="radio" id="flexRadioDefault5" />
                                <label className="form-check-label mb-0" htmlFor="flexRadioDefault5">
                                  Yes
                                </label>
                              </div>

                              <div className="form-check mb-0">
                                <Field className="form-check-input" type="radio" name="battalion_command" value="no" id="flexRadioDefault6" />
                                <label className="form-check-label mb-0" htmlFor="flexRadioDefault6">
                                  No
                                </label>
                              </div>
                            </div>
                            <ErrorMessage name={'battalion_command'} >
                              {ErrorMessageWrapper}
                            </ErrorMessage>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <div className="form-group">
                            <label htmlFor="zone_of_consideration">Zone of Consideration <span>*</span></label>
                            <Field component="select" className="form-control" id="zone" name="zone_of_consideration">
                              <option value="">Select Zone</option>
                              <option value="above_zone">Above the Zone</option>
                              <option value="primary_zone">Primary Zone</option>
                              <option value="below_zone">Below the Zone</option>
                            </Field>
                            <ErrorMessage name={'zone_of_consideration'} >
                              {ErrorMessageWrapper}
                            </ErrorMessage>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-6 col-md-6">
                          <div className="form-group">
                            <label>Age (Yrs) <span>*</span></label>
                            <Field type="number" className="form-control" placeholder="Age in Years" id="age" name="age"/>
                            <ErrorMessage name={'age'} >
                              {ErrorMessageWrapper}
                            </ErrorMessage>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <div className="form-group">
                            <label>LTC ratings <span>*</span></label>
                            <Field type="number" className="form-control" placeholder="LTC ratings" id="ltc_ratings" name="ltc_ratings"/>
                            <ErrorMessage name={'ltc_ratings'} >
                              {ErrorMessageWrapper}
                            </ErrorMessage>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-12">
                          <div className="form-group text-center">
                            <span data-bs-toggle="modal" data-bs-target="#resultModalId" id="resultModalSpanId"></span>
                            <button type="submit" className="btn btn-calc">Calculate Probability of Selection</button>
                          </div>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="takeaway" id="key">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-6">
              <div className="takeaway__Img">
                <img src={TakeAwayImg} alt={''} />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="takeaway__Content">
                <h2>Key <span>Takeaways</span></h2>
                <ul>
                  <li><b>Promotion is Never Certain.</b> No one's probability of selection is ever exactly 100% or 0%.
                    There are instances where people with excellent files are not selected. And instances where people
                    with very weak files do get selected.
                  </li>

                  <li><b>Do a Good Job.</b> The most important factor influencing selection is clearly the number of
                    Above Center of Mass ratings an individual gets from his senior rater(s). That means that there is
                    never a job in which it is "beneficial" to do a mediocre job.
                  </li>

                  <li><b>What about Senior Rater rank?</b> Is it better to get a top block rating from a 3 star than a
                    COL? Of course. The real question is "does a center of mass rating from a 3 star carry more weight
                    than from a COL?"
                  </li>

                  <li><b>Interaction between BN CMD and Ratings.</b> Clearly someone who does BN CMD and gets top
                    ratings increases their chances for selection. However, this study does not address the impact of
                    getting a center of mass rating while in BN CMD.
                  </li>

                  <li><b>Senior Rater comments.</b> As with any model, there is a certain amount of unexplained
                    variance. This may be attributable to the quality of the senior rater comments which were not
                    included in the data used for this study.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="video">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-6">
              <div className="video__Content vertical">
                <h2>Check Out My <span>Video</span></h2>
                <p>Military Promotion: Calculate Your Odds of Promotion to Colonel</p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="video__Content position-relative"
                   onMouseEnter={handleMouseEnter}
                   onMouseLeave={handleMouseLeave}
              >
                <video
                  ref={videoRef}
                  src={PromotionColonel}
                  id="video"
                ></video>
                <div className="play-button-wrapper" id="circle-play-b" onClick={toggleVideoPlay}>
                  {showPlay && <img src={isPlayingVideo ? PauseIcon : PlayBtn} alt={''} />}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="helpus" id="help">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="helpus__Content text-center">
                <h2>Help <span>Us</span></h2>
                <p>We are interested in building a larger dataset of ratings to enrich this analysis.</p>
                <p className="mb-0">If you would like to participate, leave a comment on my video.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
      <div className="modal fade" id="resultModalId" tabIndex="-1" aria-labelledby="resultModalId" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body p-0">
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              <div className="calcSection__Form m-0">
                <div className="resultModal__Content text-center">
                  <h3>COL Promotion Calculator Result</h3>
                  <p>Here is the result found! Please check</p>
                  <h2>{finalResult}%</h2>

                  <div className="form-group text-center">
                    <button type="button" className="btn btn-calc" data-bs-dismiss="modal" aria-label="Close" onClick={() => {
                      try {
                        (formikRef.current).resetForm();
                      } catch (e) {
                      }
                    }}>Calculate Another Probability of Selection</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Welcome;
