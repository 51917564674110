import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import Welcome from "./screens/welcome";
import PageNotFound from "./components/PageNotFound";
import PrivacyPolicy from "./screens/privacy";
import Terms from "./screens/terms";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Welcome />} />
          <Route path="*" element={<PageNotFound />} />
          {/*<Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms" element={<Terms />} />*/}
        </Routes>
      </Router>
    </div>
  );
}

export default App;
