import {useNavigate} from "react-router-dom";
import '../../assets/css/bootstrap.min.css'
import '../../assets/css/custom.css'
import '../../assets/css/responsive.css'
import '../../assets/css/responsive.css'
import Logo from '../../assets/img/logo.svg';

const Header = () => {
  const navigate = useNavigate();

  return (
    <nav className="navbar navbar-expand-lg bg-body-tertiary">
      <div className="container-fluid">
        <a className="navbar-brand" href="#" onClick={() => navigate('/')}>
          <img src={Logo} alt="logo"/>
        </a>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
                aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <a className="nav-link active" aria-current="page" href="#" onClick={() => navigate('/')}>Home</a>
            </li>
            <li className="nav-item" onClick={() => navigate('/')}>
              <a className="nav-link" href="#calc">Calculator</a>
            </li>

            <li className="nav-item" onClick={() => navigate('/')}>
              <a className="nav-link" href="#key">Key Takeaways</a>
            </li>

            <li className="nav-item" onClick={() => navigate('/')}>
              <a className="nav-link" href="#help">Help Us</a>
            </li>

          </ul>
        </div>
      </div>
    </nav>
  )
}
export default Header;
